'use client';

import { atom } from 'jotai';
import { DelegateInfo } from '@/app/types/delegates';
import { userDataAtom } from '@/app/atoms/userData';
import { proposalThresholdAtom } from '@/app/atoms/governorContract';
import { UserData } from '@/app/types/userData';
import { formatUnits } from 'ethers';
import { formatVotingPower } from '../helpers/formatVotingPower';

// Atom to store delegate information fetched from the API
export const delegatesAtom = atom<DelegateInfo[]>([]);

export const delegatorsAtom = atom<{
  address: `0x${string}` | null;
}>({
  address: null,
});

// Derived atom to update userDataAtom if a delegate matches the current user
export const userDataUpdateAtom = atom<UserData | null>((get) => {
  const baseUserData = get(userDataAtom);
  const delegates = get(delegatesAtom);
  const proposalThreshold = get(proposalThresholdAtom);

  if (!proposalThreshold) {
    return baseUserData;
  }

  const userAddress = baseUserData.address;
  const userDelegate = delegates.find((delegate) => delegate.address === userAddress);

  if (!userDelegate) {
    return baseUserData;
  }

  // Format the user's voting power and check if it meets the proposal threshold
  const readableVotingPower = formatUnits(userDelegate.votingPower || '0', 18);

  const formattedVotingPower = formatVotingPower(readableVotingPower) || '0'; // Ensure formattedVotingPower is a string
  const meetsProposalThreshold =
    Number(formattedVotingPower.replace(/,/g, '')) >=
    Number(proposalThreshold.replace(/,/g, ''));

  return {
    ...baseUserData,
    address: userAddress,
    delegateCount: userDelegate.delegators ? 1 : 0,
    delegatesAddress: userDelegate.delegators || [],
    delegatorsAddresses: delegates
      .filter((delegate) => delegate.delegators?.includes(userAddress))
      .map((delegate) => delegate.address),
    delegatorsCount: (userDelegate.delegators || []).length,
    isUserSelfDelegated: (userDelegate.delegators || []).includes(userAddress),
    votingPower: Number(formattedVotingPower).toLocaleString(),
    meetsProposalThreshold,
    delegatedTokens: formattedVotingPower,
    userHasVoted: false,
  };
});

// Atom that directly sorts delegates based on user's delegator status
export const sortedDelegateAtom = atom<DelegateInfo[]>((get) => {
  const delegates = get(delegatesAtom);
  const userData = get(userDataAtom);
  const address = userData.address;

  // Sort delegates based on whether the user is a delegator for them
  return [...delegates].sort((a, b) => {
    const isAUserDelegate = a.delegators?.includes(address as `0x${string}`);
    const isBUserDelegate = b.delegators?.includes(address as `0x${string}`);

    if (isAUserDelegate && !isBUserDelegate) return -1;
    if (!isAUserDelegate && isBUserDelegate) return 1;
    return 0;
  });
});

export const totalDelegationPowerAtom = atom(async () => {
  const response = await fetch(process.env.NEXT_PUBLIC_TOTAL_DELEGATED_API as string);

  const data = await response.text();
  const cleanedData = data.replace(/"/g, '');

  try {
    const bigIntValue = BigInt(cleanedData);
    const scaledValue = Number(bigIntValue) / 1e18;
    return Math.round(scaledValue * 100) / 100;
  } catch (error) {
    console.error('Failed to parse total delegation power:', error);
    return 0;
  }
});
